<template>
    <Toast />
    <div class="card">
        <DataTable :value="tasklist" :lazy="true" v-model:selection="selectedData" selectionMode="single"
            :scrollable="true" stripedRows scrollHeight="650px" :loading="loading" scrollDirection="both"
            :paginator="true" :rows="pagesize" :virtualScrollerOptions="vsOptionFun()"
            :rowsPerPageOptions="[50, 100, 200, 500]" :totalRecords="totalRecords" :first="first"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3" @page="taskPage($event)">
            <Column field="subject" header="标题" style="flex-grow:1; flex-basis:300px" frozen>
                <template #body="slotProps">
                    <Button :label="slotProps.data.subject" @click="opendetail(slotProps.data)" class="p-button-link" />
                </template>
            </Column>
            <Column field="flowname" header="流程" style="flex-grow:1; flex-basis:150px"></Column>
            <Column field="statusname" header="流程状态" style="flex-grow:1; flex-basis:150px"></Column>
            <Column field="statename" header="流程当前信息" style="flex-grow:1; flex-basis:200px"></Column>
            <Column field="createtime" header="申请时间" style="flex-grow:1; flex-basis:160px"></Column>
            <Column field="creater" header="申请人" style="flex-grow:1; flex-basis:150px"></Column>
        </DataTable>
    </div>
    <Dialog header="流程信息" v-model:visible="displayPosition" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closePosition" autofocus />
        </template>
    </Dialog>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" v-model:visible="displayMaximizable" :style="{width: '70vw'}" :maximizable="true"
        :modal="true">
        <p class="p-m-0">
            <UniversalPage ref="universalpage" :currentRow="currentRow" :isReadOnly="true" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="流程信息" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openLeftPosition" />
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" autofocus />
            </div>
        </template>
    </Dialog>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import requests from '../service/newaxios';
    import * as Base64 from 'js-base64';
    import CommonJs from '../js/Common.js';
    import UniversalPage from '../components/UniversalPage.vue';
    import FlowHistory from '../components/FlowHistory.vue';
    import MessageTip from '../components/Message';
    export default {
        data() {
            return {
                selectedData: ref(),
                currentRow: null,
                flowhistoryJson: null,
                loading: false,
                displayMaximizable: false,
                displayPosition: false,
                position: 'bottomleft',
                recordsubject: '',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                tasklist: [],
            }
        },
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.loadData(1);
        },
        methods: {
            loadData(v_page) {
                this.loading = true;
                var sysid = this.$route.query.sysid;
                if (sysid == null) {
                    sysid = '';
                }
                var historyboxlistwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'sysid',
                        value: sysid,
                        operation: '=',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{historyboxlist(where:" + JSON.stringify(historyboxlistwhere) +
                    "){id workflowid rootworkflowid flowid sysid subject statusname statename flowname bindpage flowguid createtime creater}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.tasklist = jsonData.data.historyboxlist;
                        console.log(this.historyboxlist);
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                        this.loading = false;
                        console.log('totalRecords:' + this.totalRecords);
                    }
                });
            },
            taskPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                console.log(page);
                this.loadData(page + 1);
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            onRecordClick() {

            },
            opendetail(v_row) {
                this.currentRow = v_row;
                this.recordsubject = v_row.subject;
                if (v_row.bindpage != '') {
                    this.displayMaximizable = true;
                } else {
                    MessageTip.warnmsg('类型名称不能为空');
                }
            },
            closeMaximizable() {
                console.log(this.$refs.universalpage);
                this.recordsubject = '';
                this.displayMaximizable = false;
            },
            openLeftPosition() {
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
        },
        components: {
            UniversalPage,
            FlowHistory,
        }
    }
</script>